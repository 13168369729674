import React from 'react';
import { bool } from 'prop-types';
import { Link } from "gatsby";
import dimensions from "styles/dimensions";
import styled from "@emotion/styled";
import colors from "styles/colors";

const HeaderLinks = styled("nav")`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${colors.yellow};
    height: 100vh;
    text-align: left;
    padding: 2rem;

    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 11;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(+100%)'};

    @media (min-width: ${dimensions.maxwidthDesktop}px;) {
        display: none;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px;) {
        width: 100%;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            padding: 0.5rem 0;

            a {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 0.5rem;
                color: ${colors.black};
                text-decoration: none;
                transition: color 0.3s linear;
                
                @media (max-width: ${dimensions.maxwidthMobile}px;) {
                    font-size: 1.5rem;
                    text-align: center;
                }
        
                &:hover {
                    color: ${colors.red};
                }
            }
        }

    }
`

const Menu = ({ open }) => {
        return (
            <HeaderLinks className="main-menu" aria-label="Mobilusis meniu" open={open}>
                <ul>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/naujienos">
                            Naujienos
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/projektai">
                            Projektai
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/ataskaitos">
                            Ataskaitos
                        </Link>
                    </li>
                    {/*<li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/nuotraukos">
                            Nuotraukos
                        </Link>
                    </li>*/}
                    <li>
                        <a 
                            href="https://diskusijos.antanavas.lt/feed/parduodu" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            Skelbimai
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://diskusijos.antanavas.lt" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            Forumas
                        </a>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/apie-antanava">
                            Apie mus
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/kontaktai">
                            Kontaktai
                        </Link>
                    </li>
                </ul>
            </HeaderLinks>
        )
}

Menu.propTypes = {
    open: bool.isRequired,
  }

export default Menu;