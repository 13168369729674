import React from 'react';
import { bool, func } from 'prop-types';
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";

const BurgerContainer = styled("button")`
    position: ${({open }) => open ? "fixed" : "absolute"};
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 12;

    @media (min-width: ${dimensions.maxwidthDesktop}px) {
        display: none;
    }

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background: ${({open }) => open ? colors.black : colors.green};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-of-type {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-of-type(2) {
        opacity: ${({ open }) => open ? '0' : '1'};
        transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-of-type(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const Burger = ({ open, setOpen }) => {
        return (
            <BurgerContainer open={open} onClick={() => setOpen(!open)} aria-label="Perjungti meniu">
                <div />
                <div />
                <div />
            </BurgerContainer>
        )
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
  };

export default Burger;