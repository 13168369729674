import { useEffect } from "react";

const FacebookRoot = () => {
    useEffect(() => {
        if (document && !document.getElementById('fb-root')) {
            const root = document.createElement('div');
            root.id = "fb-root";

            document.body.appendChild(root);
        }
    }, []);

    return null;
};

export default FacebookRoot;