import React from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import banner from "images/bendruomeniu_konkurso_dalyvio_baneriukas_970x90.png"

const FooterContainer = styled("div")`
    padding-top: 3.75em;
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        max-width: 50px;
    }
`

const FooterBanner = styled("a")`
    img {
        width: 100%;
    }
`

const FooterAuthor = styled("a")`
    font-size: 0.75em;
    color: ${colors.grey700};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin-top: 1.5em;

     &:hover {
         color: ${colors.blue900};

        .FooterLogo {
            animation-name: rotate;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    @keyframes rotate {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
`

const Footer = () => (
    <FooterContainer>
        <FooterBanner href="http://konkursas.beti.lt/apie-projeka/" target="_blank" rel="noopener noreferrer">
            <img alt="Prisijungusi Lietuva - Bendruomenių įtinklinimo projektų konkurso dalyvis" src={banner} width="970"></img>
        </FooterBanner>
        <FooterAuthor href="https://www.lukasjokubas.lt" target="_blank" rel="noopener noreferrer">
            © {(new Date().getFullYear())}. Sprendimas: Lukas Jokūbas Jakubauskas.
        </FooterAuthor>
    </FooterContainer>
)

export default Footer;
