import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Logo from "components/_ui/Logo";
import Burger from "components/_ui/Burger";
import Menu from "components/_ui/Menu";

const HeaderContainer = styled("div")`
    padding-top: 1em;
    overflow: visible;
`

const HeaderContent = styled("div")`
    display: flex;
    justify-content: flex-end;
    min-height: 3em;

    svg {
        margin-left: 5px;
    }

    :target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
        position: absolute;
        display: block;  
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 998;
        background: #000;
        background: rgba(0,0,0,.5);
        cursor: default;
    }

    @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
        position: fixed;
    }
}

    @media (min-width: ${dimensions.maxwidthDesktop}px;) {
        padding-top: 3.5em;
      }

`

const HeaderLinks = styled("nav")`
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    height: 100%;
    overflow-y: hidden;
    overflow-x: visible;
    transition: left 0.3s ease,
    box-shadow 0.3s ease;
    z-index: 999;
    background: rgba(255, 255, 255, 0.75);

    ul {
        list-style: none;
        margin: 0;
        /* Hide shadow w/ -8px while 'closed' */
        -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
        -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
        box-shadow: -8px 0 8px rgba(0,0,0,.5);
        min-height: 100%;
        width: 200px;
        background: #1a1a1a;

        li:first-of-type a {
            border-top: 1px solid #383838;
          }
    }

    a {
        display: block;
        padding: .75em 15px;
        line-height: 1em;
        font-size: 1em;
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #383838;

        :hover,
        :focus {
          background: #333;
          text-decoration: underline;
        }

    }

    :target,
    .main-menu[aria-expanded="true"] {
        display: block;
        left: 0;
        outline: none;
        -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
        -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
        box-shadow: 3px 0 12px rgba(0,0,0,.25);
    }

    :target ul,
    .main-menu[aria-expanded="true"] ul {
        position: relative;
        z-index: 1000;
    }

}

@media (max-width: ${dimensions.maxwidthTablet}px) { 
    position: fixed;
}

@media (min-width: ${dimensions.maxwidthDesktop}px) {
    position: relative;
	left: auto;
	height: auto;
    display: flex;
  
    ul {
        display: flex;
        
        /* Undo off-canvas styling */
        padding-top: 16px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
                box-shadow: none;
        height: auto;
        width: auto;
        background: none;
    }
  
    a {
        color: currentColor;
        text-decoration: none;
        font-weight: 600;
        font-size: 0.95em;
        height: 100%;
        border: 0 !important; /* Remove borders from off-canvas styling */
        border-bottom: 3px solid transparent;
    }
  
    &:after {
        position: absolute;
        content: "";
        bottom: 0;
        width: 18px;
        height: 3px;
        background: transparent;
        bottom: -3px;
        right: 50%;
        margin-right: -9px;
        transition: 100ms ease-in-out background;
    }

    a:hover,
    a:focus {
        background: none; /* Remove background from off-canvas styling */
    }

    &:hover {
        &:after {
            background: ${colors.blue500};
            transition: 100ms ease-in-out background;
        }
    }

}

`

const Header = () => {
    const [open, setOpen] = useState(false);
    return (
    <HeaderContainer>
        <Link to="/">
                <Logo/>
        </Link>
        <HeaderContent>

            <div>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
            </div>
  
            <HeaderLinks id="main-menu" className="main-menu" aria-label="Pagrindinis meniu">
                <ul>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/naujienos">
                            Naujienos
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/projektai">
                            Projektai
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/ataskaitos">
                            Ataskaitos
                        </Link>
                    </li>
                    {/*<li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/nuotraukos">
                            Nuotraukos
                        </Link>
                    </li>*/}
                    <li>
                        <a 
                            href="https://diskusijos.antanavas.lt/feed/parduodu" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            Skelbimai
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://diskusijos.antanavas.lt" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            Forumas
                        </a>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/apie-antanava">
                            Apie
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName="Link--is-active"
                            to="/kontaktai">
                            Kontaktai
                        </Link>
                    </li>
                </ul>
            </HeaderLinks>
        </HeaderContent>
    </HeaderContainer>
    )
}

export default Header;