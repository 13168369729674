import React from "react";
import logo from "images/antanavas-logo.png"

const styles = {
    position: 'absolute',
    zIndex: 1
  }

const Logo = () => (
    <img alt="Antanavo herbas" style={styles} src={logo} width="100"></img>
);

export default Logo;
